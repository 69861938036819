@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,600;1,700;&display=swap');

@tailwind base;
@tailwind components;

@font-face {
  font-family: "poppinsGoogleRegular";
  font-weight: 400;
  src: local("poppins"), url(./Fonts/Poppins/Poppins-Regular.ttf) format("ttf");
}


@font-face {
  font-family: "poppinsGoogleMedium";
  font-weight: 500;
  src: local("poppins"), url(./Fonts/Poppins/Poppins-Medium.ttf) format("ttf");
}

@font-face {
  font-family: "poppinsGoogleSemiBold";
  font-weight: 600;
  src: local("poppins"), url(./Fonts/Poppins/Poppins-Regular.ttf) format("ttf");
}

@font-face {
  font-family: "poppinsGoogleBold";
  font-weight: 700;
  src: local("poppins"), url(./Fonts/Poppins/Poppins-Bold.ttf) format("ttf");
}

@font-face {
  font-family: "poppinsGoogleExtraBold";
  font-weight: 800;
  src: local("poppins"),
    url(./Fonts/Poppins/Poppins-ExtraBold.ttf) format("ttf");
}

@tailwind utilities;


@layer base {
  html {
    font-family: poppinsGoogle, system-ui, sans-serif;
    /* scrollbar-gutter: stable; */
  }
}


body {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.text-limadi {
  color: #F89818;
}

.hover\:text-limadi:hover {
  color: #F89818;
}

.bg-limadi {
  background-color: #F89818;
}

.bg-limadi-light {
  background-color: #f7c686;
}

.border-limadi {
  /* border-bottom: 2px solid #F89818; */
  border-color: #F89818;
}

.master-css {
  color: red;
}

.req-field:after {
  content: " *";
  color: #f66060;
}

.table-border-outer {
  border-collapse: collapse;
  border-radius: 10px;
  border-style: hidden;
  /* hide standard table (collapsed) border */
  box-shadow: 0 0 0 1px #D1D0E2;
  /* this draws the table border  */
}

.select-style {
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
  background-position: calc(100% - 0.75rem) center !important;

  border: 1px solid #F89818;
  color: #F89818;
  outline: #F89818;
  cursor: pointer;
  font-size: 16px;

  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
}

.option-style{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;       /* Remove default arrow */
  
  background-color: white;
  border: none;
  outline: none;
}

.absolute-center {
  @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
}

/* test styles */
.text-customButton {
  @apply text-cWhite text-base font-poppins
}


.gs-img-overlay {
  background: rgb(76 73 73 / 64%);
}

.color-text:hover {
  @apply hover:text-cWhite
}

/* custom class with tailwind */
@layer components {
  .card {
    @apply bg-white p-5 shadow rounded-xl flex-col;
  }

  .gs-green-checkbox {
    accent-color: #1d9e74;
  }

  /* hg styles for border radius */
  .gs-border-radius-5 {
    @apply rounded-[5px];
  }

  /* 

    THEMES FOR BORDER COLORS

*/

  .gs-border-primary {
    @apply border-[#F89818];
  }
  .gs-border-secondary {
    @apply border-[#1D9E74];
  }
  .gs-border-main-black {
    @apply border-[#333333];
  }
  .gs-border-body {
    @apply border-[#595959];
  }
  .gs-border-icon-color {
    @apply border-[#828282];
  }
  .gs-border-placeholder {
    @apply border-[#BDBDBD];
  }
  .gs-border-line-inside-box {
    @apply border-[#E9E9E9];
  }
  .gs-border-light-gray-field {
    @apply border-[#F8F8F8];
  }
  .gs-border-light-gray-main-box {
    @apply border-[#DCDCDC];
  }
  .gs-border-list-icon-color {
    @apply border-[#607D8B];
  }
  .gs-border-red {
    @apply border-[#FF6368];
  }
  .gs-border-link-color {
    @apply border-[#4286F4];
  }
  .gs-border-white {
    @apply border-[#F8F8FF];
  }

  /* 

    THEMES FOR BACKGROUND COLORS

*/

  .gs-primary {
    @apply bg-[#F89818];
  }
  .gs-secondary {
    @apply bg-[#1D9E74];
  }
  .gs-main-black {
    @apply bg-[#333333];
  }
  .gs-body {
    @apply bg-[#595959];
  }
  .gs-icon-color {
    @apply bg-[#828282];
  }
  .gs-placeholder {
    @apply bg-[#BDBDBD];
  }
  .gs-line-inside-box {
    @apply bg-[#E9E9E9];
  }
  .gs-light-gray-field {
    @apply bg-[#F8F8F8];
  }
  .gs-light-gray-main-box {
    @apply bg-[#DCDCDC];
  }
  .gs-light-gray-secondary-box {
    @apply bg-[#F5F5F5];
  }
  .gs-list-icon-color {
    @apply bg-[#607D8B];
  }
  .gs-red {
    @apply bg-[#FF6368];
  }
  .gs-link-color {
    @apply bg-[#4286F4];
  }
  .gs-white {
    @apply bg-[#F8F8FF];
  }

  /* 
    TEXT COLOR THEMES
*/
  .gs-text-primary {
    @apply text-[#F89818];
  }
  .gs-text-secondary {
    @apply text-[#1D9E74];
  }
  .gs-text-main-black {
    @apply text-[#333333];
  }
  .gs-list-title-black {
    @apply text-[#2D2D2D];
  }
  .gs-text-body {
    @apply text-[#595959];
  }
  .gs-text-icon-color {
    @apply text-[#828282];
  }
  .gs-text-placeholder {
    @apply text-[#BDBDBD];
  }
  .gs-text-button-placeholder {
    @apply text-[#B2B2B2];
  }
  .gs-text-line-inside-box {
    @apply text-[#E9E9E9];
  }
  .gs-text-light-gray-field {
    @apply text-[#F8F8F8];
  }
  .gs-text-light-gray-main-box {
    @apply text-[#DCDCDC];
  }
  .gs-text-list-icon-color {
    @apply text-[#607D8B];
  }
  .gs-text-red {
    @apply text-[#FF6368];
  }
  .gs-text-link-color {
    @apply text-[#4286F4];
  }
  .gs-text-white {
    @apply text-[#F8F8FF];
  }

  .gs-img-overlay {
    background: rgb(76 73 73 / 64%);
  }

  /* 
    GLOBAL BUTTONS
*/

  .gs-btn-warning-outline {
    @apply text-[#F89818] hover:text-white border-[1.5px] border-[#F89818] hover:bg-[#F89818] focus:ring-0 font-medium rounded-lg text-[14px] py-2 text-center cursor-pointer;
  }
  .gs-list-icon-btn {
    @apply px-5 py-3 gs-list-icon-color rounded-[5px] text-white font-semibold cursor-pointer select-none;
  }
  .gs-full-size-secondary-btn {
    @apply flex justify-center items-center gs-secondary text-center text-white font-semibold px-5 py-3 rounded-[5px] gs-secondary cursor-pointer select-none;
  }

/* hide numeric arrows on input field */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

}

/* text-cMainBlack hover:bg-cPrimary hover:text-cWhite  */